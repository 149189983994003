<template>
  <div class="main" data-title="部门架构" v-title>
    <div class="page-header">
      <div class="page-header-body">
        <h2 class="page-header-title">部门架构</h2>
      </div>
      <div class="head-search"></div>
      <div class="head-tabs">
        <div @click="$router.push({name: 'company-department'})" class="tab curr">部门列表</div>
        <div @click="$router.push({name: 'company-employee'})" class="tab">员工列表</div>
      </div>
    </div>
    <el-card class="main-content" shadow="hover">
      <div class="dep-main">
        <div class="dep-wrapper">
          <div class="dep-header">
            <div class="dep-name">名称</div>
            <el-tooltip
              class="item"
              content="添加新部门"
              effect="dark"
              placement="right"
              v-has="'dept-create'"
            >
              <i @click="addDepartment(0)" class="el-icon-plus add-btn"></i>
            </el-tooltip>
          </div>
          <div class="de-body">
            <el-tree
              :data="departmentData"
              :expand-on-click-node="false"
              :highlight-current="true"
              :props="defaultProps"
              @node-click="handleNodeClick"
              class="dep-tree"
              default-expand-all
              node-key="pid"
            >
              <div class="tree-node" slot-scope="{ node, data }">
                <span class="label">{{ node.label }}</span>
                <span class="btns">
                  <el-dropdown placement="bottom-start" trigger="click">
                    <i :data="data" class="el-icon-more"></i>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        @click.native="addDepartment(data.pid)"
                        v-has="'dept-create'"
                      >创建子部门</el-dropdown-item>
                      <el-dropdown-item
                        @click.native="updateDepartmentName(data)"
                        v-has="'dept-edit'"
                      >修改部门名称</el-dropdown-item>
                      <!-- <el-dropdown-item divided>上移</el-dropdown-item>
                      <el-dropdown-item>下移</el-dropdown-item>-->
                      <el-dropdown-item
                        @click.native="delDepartment(data)"
                        class="btn-delete"
                        divided
                        v-has="'dept-remove'"
                      >删除部门</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </span>
              </div>
            </el-tree>
          </div>
        </div>

        <div class="emp-wrapper">
          <div class="jue-card-header">
            <div class="jue-card-title">部门员工</div>
            <div class="jue-card-btns" v-if="currPid != 0">
              <el-button
                @click="showDialog"
                class="btn"
                icon="el-icon-plus"
                type="primary"
                v-has="'dept-set_user'"
              >添加</el-button>
            </div>
          </div>
          <div class="table-wrapper">
            <el-table :data="eployeeData" class="table" style="width: 100%" height="370px">
              <el-table-column label="姓名" prop="realname" show-overflow-tooltip></el-table-column>
              <el-table-column label="手机" prop="mobile" show-overflow-tooltip></el-table-column>
              <el-table-column label="角色" show-overflow-tooltip>
                <template slot-scope="scope">{{getRole(scope.row.group_id).label}}</template>
              </el-table-column>
              <el-table-column label="工号" prop="code" show-overflow-tooltip></el-table-column>
              <el-table-column label="性别" prop="sex" show-overflow-tooltip>
                <template slot-scope="scope">{{scope.row.sex == '1' ? '男' : '女'}}</template>
              </el-table-column>
              <el-table-column fixed="right" label="更多" width="60px">
                <template slot-scope="scope">
                  <el-dropdown placement="bottom-start" trigger="click">
                    <i class="el-icon-more"></i>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        @click.native="resetPassword(scope.row)"
                        v-has="'muser-reset_pwd'"
                      >初始化密码</el-dropdown-item>
                      <el-dropdown-item
                        @click.native="showTask(scope.row)"
                        v-has="'task-create'"
                      >设置任务</el-dropdown-item>
                      <el-dropdown-item
                        @click.native="deleteItem(scope.$index, scope.row)"
                        class="btn-delete"
                        divided
                        v-has="'dept-set_user'"
                      >移除</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </el-card>

    <department-employee :pid="currPid" @getEployeeData="getEployeeData" ref="departmentEmployee"></department-employee>
    <set-task ref="task"></set-task>
  </div>
</template>
<script>
import DepartmentEmployee from '@/components/DepartmentEmployee.vue'
import SetTask from '@/components/SetTask.vue'
import { roleByID } from '@/unit/unit.js'
export default {
  components: {
    DepartmentEmployee,
    SetTask
  },
  data() {
    return {
      base: {
        batchBtn: false,
        text: ''
      },
      currPid: 0, //当前已选中部门的pid，默认全公司
      defaultPid: [],
      eployeeData: [],
      departmentData: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      newdepartment: {
        parent_pid: 0,
        name: ''
      }
    }
  },
  mounted() {
    this.getDepartmentData()
  },
  methods: {
    //显示设置任务界面
    showTask(data) {
      this.$refs.task.add(data)
    },
    //移除员工
    deleteItem(index, item) {
      this.$confirm(
        '此操作将员工' + item.realname + ' 从部门移除  , 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          this.$axios
            .post('/dept/set_user', {
              act: 'del',
              pid: this.currPid,
              uids: [item.uid]
            })
            .then(res => {
              if (res.data.code == 200) {
                this.$message.success(res.data.msg)
                this.getEployeeData()
              } else {
                this.$message.error(res.data.msg)
              }
            })
        })
        .catch(() => {})
    },
    delDepartment(data) {
      this.$confirm('此操作将永久删除 ' + data.name + ' , 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$axios
            .post('/dept/remove', {
              pid: data.pid
            })
            .then(res => {
              if (res.data.code == 200) {
                this.$message.success(res.data.msg)
                this.getDepartmentData()
              } else {
                this.$message.error(res.data.msg)
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    getRole(id) {
      return roleByID(id)
    },
    showDialog() {
      this.$refs.departmentEmployee.show(this.currPid)
    },
    getEployeeData() {
      this.$axios
        .post('/muser/lists', {
          dept_id: this.currPid,
          count: 10000
        })
        .then(res => {
          if (res.data.code == 200) {
            //更新用户列表
            this.eployeeData = res.data.data.list
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    //获取部门列表
    getDepartmentData() {
      this.$axios.post('/dept/lists').then(res => {
        if (res.data.code == 200) {
          this.departmentData = res.data.data
        }
      })
    },
    handleNodeClick(data) {
      this.currPid = data.pid
      this.getEployeeData(data.pid)
    },
    //创建部门
    addDepartment(num) {
      this.newdepartment.parent_pid = num
      let _this = this

      this.$prompt('输入部门名称', '新建', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: name
      })
        .then(({ value }) => {
          this.newdepartment.name = value
          this.$axios.post('/dept/create', this.newdepartment).then(res => {
            if (res.data.code == 200) {
              this.$message.success(res.data.msg)
              _this.getDepartmentData()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        })
        .catch(() => {
          this.newdepartment.name = ''
        })
    },
    //修改部门名称
    updateDepartmentName(data) {
      let _this = this
      this.newdepartment.pid = data.pid

      this.$prompt('输入新的部门名称', '修改', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: data.name
      })
        .then(({ value }) => {
          this.newdepartment.name = value
          this.newdepartment.order = 0
          this.$axios.post('/dept/edit', this.newdepartment).then(res => {
            if (res.data.code == 200) {
              this.$message.success(res.data.msg)
              _this.getDepartmentData()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        })
        .catch(() => {
          this.newdepartment.name = ''
        })
    },
    //初始化密码
    resetPassword(data) {
      this.$confirm(
        '此操作将员工' + data.realname + ' 的密码初始化  , 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          this.$axios
            .post('/muser/reset_pwd', {
              uids: [data.uid]
            })
            .then(res => {
              if (res.data.code == 200) {
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
            })
        })
        .catch(() => {})
    }
  }
}
</script>
<style lang="less" scoped>
.dep-main {
  display: flex;
}

.dep-wrapper {
  width: 300px;
  margin: -20px 0 0 -20px;
  border-right: 1px solid #e8e8e8;
  .dep-header {
    height: 64px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e8e8e8;
    .dep-name {
      flex: 1;
      padding-left: 20px;
      font-size: 16px;
    }
    .add-btn {
      padding: 8px;
      cursor: pointer;
    }
  }
}

.dep-tree {
  /deep/ .el-tree-node__content {
    height: 53px;
    position: relative;
  }
  /deep/ .el-tree-node.is-current > .el-tree-node__content {
    background: #e6f7ff;
    font-weight: 500;
    color: #409eff;
    border-right: 3px #409eff solid;
  }
}

.tree-node {
  display: flex;
  align-items: center;
  width: 100%;
  height: 53px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  // &::after {
  //   display: block;
  //   content: ' ';
  //   border-bottom: 1px solid #e8e8e8;
  //   height: 1px;
  //   width: 100%;
  //   position: absolute;
  //   right: 0;
  //   bottom: 0;
  // }
  .label {
    flex: 1;
  }
}

.el-icon-more {
  transform: rotate(90deg);
  font-size: 12px;
  padding: 8px;
  color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  &:hover {
    color: rgba(0, 0, 0, 0.65);
  }
}

.emp-wrapper {
  flex: 1;
  .jue-card-header {
    margin: -20px -20px 0 0;
  }
}

.table-wrapper {
  margin-right: -20px;
  /deep/ .el-table {
    overflow: auto;
  }
}
</style>