<template>
  <!-- 数量编辑 -->
  <el-dialog :before-close="resetForm" :visible.sync="dialogVisible" title="选择员工">
    <div style="text-align: center">
      <el-transfer
        :data="allEployeeData"
        :titles="['所有员工', '部门员工']"
        @change="handleChange"
        class="transfer"
        filter-placeholder="员工姓名"
        filterable
        v-model="employeeData"
      ></el-transfer>
    </div>

    <span class="dialog-footer" slot="footer">
      <el-button @click="resetForm">取 消</el-button>
      <el-button @click="resetForm" type="primary">完 成</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      pid: 0,
      dialogVisible: false,
      allEployeeData: [],
      employeeData: []
    }
  },
  mounted() {},
  methods: {
    handleChange(value, direction, movedKeys) {
      if (this.pid == 0) {
        this.$message.error('请选择部门')
        return false
      }
      let type = direction == 'right' ? 'add' : 'del'
      this.$axios
        .post('/dept/set_user', {
          act: type,
          pid: this.pid,
          uids: movedKeys
        })
        .then(res => {
          if (res.data.code == 200) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    show(pid) {
      this.pid = pid
      this.getAllEployeeData()
      this.dialogVisible = true
    },

    //获取配置数据列表
    getAllEployeeData() {
      this.$axios
        .post('/muser/lists', {
          status: 1,
          count: 10000
        })
        .then(res => {
          if (res.data.code == 200) {
            //更新用户列表
            res.data.data.list.forEach((item, index) => {
              if (item.role != 10000) {
                this.allEployeeData.push({
                  label: item.realname,
                  key: item.uid
                })
              }

              if (item.department.indexOf(this.pid) != -1) {
                this.employeeData.push(item.uid)
              }
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },

    submitForm() {
      this.$axios
        .post('/dept/set_user', {
          act: 'add',
          pid: this.pid,
          uids: this.employeeData
        })
        .then(res => {
          if (res.data.code == 200) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    resetForm(formName) {
      this.allEployeeData = []
      this.employeeData = []
      this.$emit('getEployeeData')
      this.dialogVisible = false
    }
  }
}
</script>
<style lang="less" scoped>
.transfer {
  display: inline-block;
  text-align: left;
}
</style>