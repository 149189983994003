<template>
  <el-drawer :visible.sync="visible" :with-header="false" size="600px">
    <div class="jue-drawer-header">
      <div class="jue-drawer-title">设置任务</div>
      <div class="jue-drawer-btns">
        <i @click="visible = false" class="el-icon-close"></i>
      </div>
    </div>

    <div class="jue-drawer-body">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form class="form" label-position="right" label-width="120px">
            <el-form-item label="员工姓名: ">{{info.realname}}</el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <el-form class="form" label-position="right" label-width="120px">
            <el-form-item label="职务:">{{getRole(info.role).label}}</el-form-item>
          </el-form>
        </el-col>
      </el-row>

      <el-divider content-position="left">回访任务</el-divider>
      <el-form :model="formTel" class="form" label-position="right" label-width="120px">
        <el-form-item label="电话量: ">
          <el-input-number :max="100" :min="0" controls-position="right" v-model="formTel.quantity"></el-input-number>
          <span style="margin-left:20px;">/天</span>
        </el-form-item>
        <el-form-item label="电话周期: ">
          <el-date-picker
            end-placeholder="结束日期"
            range-separator="-"
            start-placeholder="开始日期"
            type="daterange"
            v-model="formTel.telDate"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="备注: ">
          <el-input
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请输入内容"
            type="textarea"
            v-model="formTel.memo"
          ></el-input>
        </el-form-item>
      </el-form>

      <el-divider content-position="left" v-if="info.role != 2">检核任务</el-divider>
      <el-form :model="formIns" class="form" label-position="right" label-width="120px" v-if="info.role != 2">
        <el-form-item label="检核量: ">
          <el-input-number :max="100" :min="0" controls-position="right" v-model="formIns.quantity"></el-input-number>
          <span style="margin-left:20px;">条</span>
        </el-form-item>
        <el-form-item label="检核周期: ">
          <el-date-picker
            end-placeholder="结束日期"
            range-separator="-"
            start-placeholder="开始日期"
            type="daterange"
            v-model="formIns.insDate"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="备注: ">
          <el-input
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请输入内容"
            type="textarea"
            v-model="formIns.memo"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>

    <div class="form-footer">
      <div>
        <el-button @click="resetForm">取 消</el-button>
        <el-button @click="submitForm" type="primary">提 交</el-button>
      </div>
    </div>
  </el-drawer>
</template>
<script>
import { roleByID } from '@/unit/unit.js'
export default {
  data() {
    return {
      visible: false,
      info: {},
      formTel: {
        task_type: 1,
        telDate: '',
        quantity: '0',
        memo: ''
      },
      formIns: {
        task_type: 2,
        insDate: '',
        quantity: '0',
        memo: ''
      }
    }
  },
  methods: {
    add(data) {
      this.visible = true
      this.info = data
    },
    submitForm() {
      if (this.formTel.quantity > 0 && this.formTel.telDate != '') {
        this.$axios
          .post('/task/create', {
            task_uid: this.info.uid,
            task_type: 1,
            begin_date: this.formTel.telDate[0],
            end_date: this.formTel.telDate[1],
            quantity: this.formTel.quantity,
            memo: this.formTel.memo
          })
          .then(res => {
            if (res.data.code == 200) {
              this.$message.success(res.data.msg)
            }
          })
      }

      if (this.formIns.quantity > 0 && this.formIns.insDate != '') {
        this.$axios
          .post('/task/create', {
            task_uid: this.info.uid,
            task_type: 2,
            begin_date: this.formIns.insDate[0],
            end_date: this.formIns.insDate[1],
            quantity: this.formTel.quantity,
            memo: this.formTel.memo
          })
          .then(res => {
            if (res.data.code == 200) {
              this.$message.success(res.data.msg)
            }
          })
      }

      if (
        (this.formTel.quantity == 0 || this.formTel.telDate == '') &&
        (this.formIns.quantity == 0 || this.formIns.insDate == '')
      ) {
        // this.$message.error('任务没有设置完事')
      }

      this.resetForm()
    },
    getRole(id) {
      if(id){
        return roleByID(id)
      }else{
        return {
          label: ''
        }
      }
      
    },
    resetForm() {
      this.formTel.telDate = ''
      this.formTel.quantity = 0
      this.formTel.memo = ''

      this.formIns.insDate = ''
      this.formIns.quantity = 0
      this.formIns.memo = ''

      this.visible = false
    }
  }
}
</script>
<style lang="less" scoped>
.jue-drawer-body {
  padding: 0 40px;
}

.w100 {
  width: 100%;
}

.form {
  padding: 20px 0;
}

.form-footer {
  text-align: right;
  width: 600px;
  padding: 0 40px 0 0;
  el-button {
    margin-left: 20px;
  }
}
</style>